<script setup>
import { useRoute } from 'vue-router'
const route = useRoute()

const routesWithGrayBackground = ['account', 'appointment', 'proposal', 'services', 'login']
const backgroundColor = computed(() => routesWithGrayBackground.some(name => route.name.startsWith(name)) ? 'bg-white-500' : '')
</script>
<template>
  <div :class="[backgroundColor, 'flex flex-col']">
    <div class="flex-1 flex flex-col min-h-dvh lg:h-full w-full">
      <NavigationBar />
      <div class="flex-grow flex flex-col">
        <NuxtPage />
      </div>
    </div>
    <Footer />
  </div>
</template>
